import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      items: { }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        if (this.id) {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    async onInitData() {
      try {
        this.loading = true
        const param = {
          _id: this.id
        }
        const result = await this.$store.dispatch('Customers/get', param, { root: true })
        this.loading = false
        if (result.status === 200) {
          this.items = result.data
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.items = {}
      this.$emit('close-event', { 
        status: true
      })
    }
  }
}
